/** @format */

import React, { useState, useEffect } from "react";

const OrgCovers = () => {
  return (
    <>
      <div>All Organic Covers Go Here</div>
    </>
  );
};

export default OrgCovers;
