/** @format */

import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import logo from "../assets/Eli Image.jpg";
import logo3 from "../assets/Pink.jpeg";
import logo4 from "../assets/Navy.jpeg";
import logo5 from "../assets/Plum.jpeg";
import logo6 from "../assets/PowderBlue.jpeg";
import logo7 from "../assets/SilverGrey.jpeg";
import logo8 from "../assets/SlateGrey.jpeg";
import logo9 from "../assets/Thyme.jpeg";
import logo16 from "../assets/Image2.jpg";
import logo17 from "../assets/Image3.jpg";
import logo18 from "../assets/Image4.jpg";
import logo12 from "../assets/Image 5.jpg";
import logo13 from "../assets/champagne.jpeg";
import logo14 from "../assets/rose.jpeg";
import logo15 from "../assets/sky.jpeg";

const BuyNow = ({ allProds }) => {
  const { no } = useParams();
  console.log("no", no);
  console.log("useParams()", useParams());
  console.log(allProds, "allProds");
  if (!useParams()) {
    return <Center>Loading...</Center>;
  }

  return (
    <Wrapper>
      <Leftdiv>
        <Avatar
          alt="avatar"
          style={{ width: 400 }}
          src={
            Object.values(allProds).filter((items) => items.no === no)[0]
              .avatarSrc
          }
        />
      </Leftdiv>
      <Rightdiv>
        {" "}
        <Description>
          {" "}
          {
            Object.values(allProds).filter((items) => items.no === no)[0]
              .description
          }{" "}
        </Description>
        <Color>
          {Object.values(allProds).filter((items) => items.no === no)[0].color}
        </Color>
        {no === "1" ? (
          <a
            href="https://amzn.to/3IwqtRw"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <img src={logo} alt="Product Name" style={{ width: 120 }} />
            <div
              style={{
                marginTop: 10,
              }}
            >
              Buy now on Amazon.co.uk
            </div>
          </a>
        ) : no === "3" ? (
          <a
            href="https://amzn.to/3IxQoZ7"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <img src={logo3} alt="Product Name" style={{ width: 120 }} />
            <div
              style={{
                marginTop: 10,
              }}
            >
              Buy now on Amazon.co.uk
            </div>
          </a>
        ) : no === "4" ? (
          <a
            href="https://amzn.to/3wVWLCL"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <img src={logo4} alt="Product Name" style={{ width: 120 }} />
            <div
              style={{
                marginTop: 10,
              }}
            >
              Buy now on Amazon.co.uk
            </div>
          </a>
        ) : no === "5" ? (
          <a
            href="https://amzn.to/4c8nkVp"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <img src={logo5} alt="Product Name" style={{ width: 120 }} />
            <div
              style={{
                marginTop: 10,
              }}
            >
              Buy now on Amazon.co.uk
            </div>
          </a>
        ) : no === "6" ? (
          <a
            href="https://amzn.to/3wH2NHk"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <img src={logo6} alt="Product Name" style={{ width: 120 }} />
            <div
              style={{
                marginTop: 10,
              }}
            >
              Buy now on Amazon.co.uk
            </div>
          </a>
        ) : no === "7" ? (
          <a
            href="https://amzn.to/3IsGaJB"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <img src={logo7} alt="Product Name" style={{ width: 120 }} />
            <div
              style={{
                marginTop: 10,
              }}
            >
              Buy now on Amazon.co.uk
            </div>
          </a>
        ) : no === "8" ? (
          <a
            href="https://amzn.to/48OD5OL"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <img src={logo8} alt="Product Name" style={{ width: 120 }} />
            <div
              style={{
                marginTop: 10,
              }}
            >
              Buy now on Amazon.co.uk
            </div>
          </a>
        ) : no === "9" ? (
          <a
            href="https://amzn.to/49IT0zn"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <img src={logo9} alt="Product Name" style={{ width: 120 }} />
            <div
              style={{
                marginTop: 10,
              }}
            >
              Buy now on Amazon.co.uk
            </div>
          </a>
        ) : // : no === "10" ? (
        //   <a
        //     href="https://amzn.to/3IxQoZ7"
        //     target="_blank"
        //     rel="noopener noreferrer"
        //   >
        //     <img src={logo3} alt="Product Name" style={{ width: 120 }} />
        //   </a>
        // ) : no === "11" ? (
        //   <a
        //     href="https://amzn.to/3IxQoZ7"
        //     target="_blank"
        //     rel="noopener noreferrer"
        //   >
        //     <img src={logo3} alt="Product Name" style={{ width: 120 }} />
        //   </a>
        // )
        no === "12" ? (
          <a
            href="https://amzn.to/43d2Vus"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <img src={logo12} alt="Product Name" style={{ width: 120 }} />
            <div
              style={{
                marginTop: 10,
              }}
            >
              Buy now on Amazon.co.uk
            </div>
          </a>
        ) : no === "13" ? (
          <a
            href="https://amzn.to/48Mq3B6"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <img src={logo13} alt="Product Name" style={{ width: 120 }} />
            <div
              style={{
                marginTop: 10,
              }}
            >
              Buy now on Amazon.co.uk
            </div>
          </a>
        ) : no === "14" ? (
          <a
            href="https://amzn.to/3wH5kBk"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <img src={logo14} alt="Product Name" style={{ width: 120 }} />
            <div
              style={{
                marginTop: 10,
              }}
            >
              Buy now on Amazon.co.uk
            </div>
          </a>
        ) : no === "15" ? (
          <a
            href="https://amzn.to/48MqyLu"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <img src={logo15} alt="Product Name" style={{ width: 120 }} />
            <div
              style={{
                marginTop: 10,
              }}
            >
              Buy now on Amazon.co.uk
            </div>
          </a>
        ) : no === "16" ? (
          <a
            href="https://amzn.to/3T8HPZy"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <img src={logo16} alt="Product Name" style={{ width: 120 }} />
            <div
              style={{
                marginTop: 10,
              }}
            >
              Buy now on Amazon.co.uk
            </div>
          </a>
        ) : no === "17" ? (
          <a
            href="https://amzn.to/49XbpIw"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <img src={logo17} alt="Product Name" style={{ width: 120 }} />
            <div
              style={{
                marginTop: 10,
              }}
            >
              Buy now on Amazon.co.uk
            </div>
          </a>
        ) : no === "18" ? (
          // <a
          //   href="https://amzn.to/3IxQoZ7"
          //   target="_blank"
          //   rel="noopener noreferrer"
          // >
          <img src={logo18} alt="Product Name" style={{ width: 120 }} />
        ) : (
          // </a>
          "no item selected"
        )}{" "}
        <Text>
          **All BebeChic Products are available with free postage through
          'Fulfilled by Amazon' and Amazon Prime. Just follow the link above:**
        </Text>
      </Rightdiv>
    </Wrapper>
  );
};

export default BuyNow;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
`;
const Description = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: bolder;
  font-size: 24px;
  line-height: 33px;
`;
const Color = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: bolder;
  font-size: 24px;
  line-height: 33px;
`;
const Center = styled.div`
  display: flex;
  flex-direction: column;
`;
const Leftdiv = styled.div`
  margin-top: 60px;
  display: flex;
  width: 50vw;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
`;
const Rightdiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50vw;
  margin-right: 100px;
`;

const Text = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-content: center;
  justify-items: center;
  width: 20vw;
  margin-top: 30px;
`;

const Avatar = styled.img`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 250px;
`;
