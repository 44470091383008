/** @format */

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import logo from "../assets/Eli Image.jpg";
import logo1 from "../assets/line.png";
import logo2 from "../assets/3Blankets.jpg";
import logo3 from "../assets/image-1a.png";
import logo4 from "../assets/Organic-home-image.jpg";
import logo5 from "../assets/new1.png";
import { AiFillHeart } from "react-icons/ai";
import { Link } from "react-router-dom";
import "./Toggle.css";
import { Helmet } from "react-helmet-async";

const Homepage = () => {
  return (
    <>
      <Helmet>
        <title>BebeChic UK Breastfeeding Covers</title>
        <meta
          name="BebeChic.UK - 100% Cotton Oeko-Tex Certified Breastfeeding Covers"
          content="BebeChic.UK - Made in the UK - 100% Cotton Oeko-Tex Certified Breastfeeding 
          Covers - Available on Amazon.co.uk"
        />
      </Helmet>
      <Wrapper>
        <ImagesWrapper>
          <Logo>
            <ImgLeft
              src={logo}
              alt="Logo"
              style={{ objectFit: "contain", height: "100%" }}
            />
          </Logo>
          <BackgroundColour>
            <Title>BebeChic.UK</Title>
            <img src={logo1} alt="Pic2" style={{ width: 100 }} />
            <LowerTitle>Specializing in:</LowerTitle>
            <Link1 style={{ textDecoration: "none" }} to={`covers/${"cotton"}`}>
              <LowerText2>100% Cotton Breastfeeding Covers</LowerText2>
            </Link1>
            <And>+</And>
            <Link1 style={{ textDecoration: "none" }} to={`covers/${"cotton"}`}>
              <LowerText2>
                Oeko-Tex Certified 100% Cotton Breastfeeding Covers
              </LowerText2>
            </Link1>
            <And>+</And>
            <Link1
              style={{ textDecoration: "none" }}
              to={`covers/${"organic"}`}
            >
              <LowerText2>100% Organic Cotton Breastfeeding Covers</LowerText2>
            </Link1>
            <And>+</And>
            <Link1
              style={{ textDecoration: "none" }}
              to={`covers/${"blanket"}`}
            >
              <LowerText3>
                100% Organic Bamboo Cellular Baby Blankets
              </LowerText3>
            </Link1>
            <Heart>
              <AiFillHeart size="25" />
            </Heart>
            <ButtonDiv>
              <Link1 to={`covers/${"all"}`}>
                <ShopNowButton>SHOP ALL PRODUCTS</ShopNowButton>
              </Link1>
            </ButtonDiv>
          </BackgroundColour>
        </ImagesWrapper>

        <FirstSection>
          <Title>Products</Title>
          <PhotosWrapper>
            <ColumnDiv>
              <Link1 to={`covers/${"cotton"}`}>
                <div class="container">
                  <Img
                    src={logo3}
                    alt="Pic2"
                    style={{
                      objectFit: "cover",
                      width: 315,
                      margin: 40,
                      height: 310,
                      border: "1px solid #f0f1f2",
                    }}
                  />
                  <div class="after">
                    <TitleBottom>
                      Oeko-Tex Cotton Breastfeeding Covers
                    </TitleBottom>
                  </div>
                </div>
                <TextUnder>Shop Oeko-Tex Cotton Breastfeeding Covers</TextUnder>
              </Link1>
            </ColumnDiv>

            <ColumnDiv>
              {" "}
              <Link1 to={`covers/${"organic"}`}>
                <div class="container">
                  <Img
                    src={logo4}
                    alt="Pic2"
                    style={{
                      width: 315,
                      margin: 40,
                      height: 310,
                      border: "1px solid #f0f1f2",
                    }}
                  />
                  <div class="after">
                    <TitleBottom>
                      Organic Cotton Breastfeeding Covers
                    </TitleBottom>
                  </div>
                </div>
                <TextUnder>Shop Organic Cotton Breastfeeding Covers</TextUnder>{" "}
              </Link1>
            </ColumnDiv>

            <ColumnDiv>
              {" "}
              <Link1 to={`covers/${"blanket"}`}>
                <div class="container">
                  <Img
                    src={logo2}
                    alt="Pic2"
                    style={{
                      width: 315,
                      margin: 40,
                      height: 310,
                      border: "1px solid #f0f1f2",
                    }}
                  />
                  <div class="after">
                    <TitleBottom>Organic Bamboo Baby Blankets</TitleBottom>
                  </div>
                </div>
                <TextUnder>Shop Organic Bamboo Baby Blankets</TextUnder>
              </Link1>
            </ColumnDiv>
          </PhotosWrapper>
        </FirstSection>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  min-height: 81vh;
  background-color: #ffffff;
`;
const ImagesWrapper = styled.div`
  display: flex;
  min-height: 600px;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  height: fit-content;
  background-color: #ffffff;
  /* margin-bottom: 10px; */
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    height: fit-content;
  }
`;
const BackgroundColour = styled.div`
  min-height: 600px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: fit-content;
  background-color: #f7d7db;
  @media (max-width: 1000px) {
    width: 100vw;
  }
`;
const Logo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  background-color: white;
  // min-height: 600px;
  @media (max-width: 1000px) {
    display: none;
  }
`;
const ImgLeft = styled.img`
  width: 100%;
  max-height: 600px;
  min-height: 550px;
`;

const Img = styled.img`
  max-width: 315;
  margin: 40px;
  height: 310;
  border-radius: 38.8548px;
  border: "1px solid #f0f1f2";
`;
const Link1 = styled(Link)`
  text-decoration: none;
  color: black;
  &:hover {
    color: #c91830;
  }
  cursor: pointer;
`;
const TextUnder = styled.div`
  padding-left: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  max-width: 320px;
  text-align: center;
`;
const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center;
  font-size: 20px; */
`;
const TitleBottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 310px;
  width: 310px;
  font-family: Crimson Text;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
  text-align: center;
`;

const FirstSection = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  background-color: #f2e6e1;
  padding-top: 40px;
  padding-bottom: 20px;
  margin-bottom: 60px;
`;

const PhotosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 90%;
  justify-content: center;
  margin: 40px;
`;
const Heart = styled.div``;
const ShopNowButton = styled.button`
  display: flex;
  height: 40px;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  width: 220px;
  background-color: transparent;
  border-width: 0.5px;
  font-weight: 1;
  &:hover {
    color: #c91830;
  }
  cursor: pointer;
`;
const ButtonDiv = styled.div`
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  font-weight: 1;
`;

const Title = styled.div`
  font-size: 34px;
  font-weight: 700;
`;
const LowerTitle = styled.div`
  height: 40px;
  font-size: 19px;
  justify-content: center;
`;
const LowerText1 = styled.div`
  display: flex;
  font-size: 19px;
  height: 40px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

const LowerText2 = styled.div`
  display: flex;
  font-size: 19px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding-bottom: 10px;
  padding-top: 10px;
`;

const LowerText3 = styled.div`
  display: flex;
  text-decoration: none;
  font-size: 19px;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 10px;
`;
const And = styled.div`
  font-size: 16px;
`;

export default Homepage;
