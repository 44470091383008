/** @format */

export const coversDot = {
  black: {
    no: "1",
    id: "black",
    description: "Dot Breastfeeding Cover",
    color: "Black + White",
    price: "£15.99",
    avatarSrc: "/assets/black.jpeg",
    href: "https://amzn.to/3IwqtRw",
  },
  duskyPink: {
    no: "3",
    id: "duskyPink",
    description: "Dot Breastfeeding Cover",
    color: "Dusky Pink + White",
    price: "£15.99",
    avatarSrc: "/assets/Pink.jpeg",
    href: "https://amzn.to/3IxQoZ7",
  },
  navy: {
    no: "4",
    id: "navy",
    description: "Dot Breastfeeding Cover",
    color: "Navy + White",
    price: "£15.99",
    avatarSrc: "/assets/Navy.jpeg",
    href: "https://amzn.to/3wVWLCL",
  },
  plum: {
    no: "5",
    id: "plum",
    description: "Dot Breastfeeding Cover",
    color: "Plum + Ivory",
    price: "£15.99",
    avatarSrc: "/assets/Plum.jpeg",
    href: "https://amzn.to/4c8nkVp",
  },
  powderBlue: {
    no: "6",
    id: "powderBlue",
    description: "Dot Breastfeeding Cover",
    color: "Powder Blue + White",
    price: "£15.99",
    avatarSrc: "/assets/powderBlue.jpeg",
    href: "https://amzn.to/3wH2NHk",
  },
  silverGrey: {
    no: "7",
    id: "silverGrey",
    description: "Dot Breastfeeding Cover",
    color: "Silver Grey + White",
    price: "£15.99",
    avatarSrc: "/assets/silverGrey.jpeg",
    href: "https://amzn.to/3IsGaJB",
  },
  slateGrey: {
    no: "8",
    id: "slateGrey",
    description: "Dot Breastfeeding Cover",
    color: "Slate Grey + White",
    price: "£15.99",
    avatarSrc: "/assets/slateGrey.jpeg",
    href: "https://amzn.to/48OD5OL",
  },
  thyme: {
    no: "9",
    id: "thyme",
    description: "Dot Breastfeeding Cover",
    color: "Wild Thyme + White",
    price: "£15.99",
    avatarSrc: "/assets/Thyme.jpeg",
    href: "https://amzn.to/49IT0zn",
  },
};

export const coversFloral = {
  summerFloral: {
    no: "16",
    id: "summerFloral",
    description: "Floral Breastfeeding Cover",
    color: "Summer Floral",
    price: "£15.99",
    avatarSrc: "/assets/Image2.jpg",
    href: "https://amzn.to/3T8HPZy",
  },
  navyDitsy: {
    no: "17",
    id: "navyDitsy",
    description: "Floral Breastfeeding Cover",
    color: "Navy Ditsy Floral",
    price: "£15.99",
    avatarSrc: "/assets/Image3.jpg",
    href: "https://amzn.to/49XbpIw",
  },
  blackDitsy: {
    no: "18",
    id: "blackDitsy",
    description: "Floral Breastfeeding Cover",
    color: "Black Ditsy Floral",
    price: "£15.99",
    avatarSrc: "/assets/Image4.jpg",
    href: "",
  },
};

export const coversOrganicCotton = {
  blackOrg: {
    no: "12",
    id: "organicBlack",
    description: "Organic Breastfeeding Cover",
    color: "Organic Black",
    price: "£19.99",
    avatarSrc: "/assets/Image 5.jpg",
    href: "https://amzn.to/43d2Vus",
  },
};

export const blanketsAll = {
  champagne: {
    no: "13",
    id: "champagne",
    description: "Bamboo Cellular Baby Blanket",
    color: "Natural White + Champagne",
    price: "£19.99",
    avatarSrc: "/assets/champagne.jpeg",
    href: "https://amzn.to/48Mq3B6",
  },
  rose: {
    no: "14",
    id: "rose",
    description: "Bamboo Cellular Baby Blanket",
    color: "Natural White + Rose",
    price: "£19.99",
    avatarSrc: "/assets/rose.jpeg",
    href: "https://amzn.to/3wH5kBk",
  },
  sky: {
    no: "15",
    id: "sky",
    description: "Bamboo Cellular Baby Blanket",
    color: "Natural White + Sky",
    price: "£19.99",
    avatarSrc: "/assets/sky.jpeg",
    href: "https://amzn.to/48MqyLu",
  },
};
