/** @format */

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import logo from "../assets/ContactBanner5.jpg";
import ContactForm from "./ContactForm";
import "./App.css";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ImageBanner>
        <img src={logo} alt="Logo" height="200px" />
      </ImageBanner>
      <TextImage>Contact Us!</TextImage>
      <Wrapper>
        <WrapperText>
          <TextTop>WHERE WE ARE</TextTop>
          <Text>We are based in New Milton, Hampshire BH25 6SN</Text>{" "}
          <TextTop>OUR WEBSITE</TextTop>
          <Text>www.bebechic.uk</Text>
          <TextTop>OUR CUSTOMER SERVICE</TextTop>
          <Text>
            Our Customer Service is second to none. You can be confident that
            any problems will be sorted out quickly and efficiently.
          </Text>
          <Text>
            For any queries please contact us - you can email or phone.
          </Text>
          <TextTop>OUR EMAIL ADDRESS</TextTop>
          <Text>info@bebechic.uk</Text> <TextTop>OUR PHONE NUMBER</TextTop>
          <Text>07818 027 954</Text>
        </WrapperText>
      </Wrapper>
    </>
  );
};

export default Contact;
const TextImage = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    font-size: 50px;
    color: pink;
    font-weight: 700;
    margin-top: 30px;
    font-style: italic;
  }
`;
const TextTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 25px;
  font-size: 20px;
  color: #3498db;
  font-weight: 700;
`;
const ImageBanner = styled.div`
  display: flex;
  justify-content: center;
  box-shadow: 4px 0px 4px 4px rgba(0, 0, 0, 0.03);
  @media (max-width: 600px) {
    display: none;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1400px;
`;

const WrapperCF = styled.div`
  display: flex;
  width: 75vw;
  justify-content: center;
`;

const WrapperText = styled.div`
  text-align: center;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  align-items: center;
  background-color: #f7d7db;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 65px;
  margin-bottom: 45px;
`;
const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  font-size: 17px;
`;
